import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule as NgrxStoreModule } from "@ngrx/store";
import {
    PRODUCT_SELECTION_FEATURE_KEY,
    productSelectionReducer,
} from "./product-selection.reducer";

@NgModule({
    imports: [
        CommonModule,
        NgrxStoreModule.forFeature(PRODUCT_SELECTION_FEATURE_KEY, productSelectionReducer),
    ],
})
export class ProductSelectionModule {}
