<header class="header">
    <div class="container">
        <div class="row" style="margin: 0">
            <div class="pull-right">
                <a (click)="goToProductAdvisor()">
                    <img src="../../../assets/lht-layout-full-page/LHT_Logo.png" alt="LHT Logo" />
                </a>
            </div>
        </div>
    </div>
</header>
<div class="container" style="margin-top: 1.5rem">
    <div *ngIf="checkRouter()">
        <div class="profile-cont">
            <button
                mat-flat-button
                [matMenuTriggerFor]="appMenu"
                id="welcomeButton"
                class="btn btn-primary"
            >
                <i
                    class="lh-icon lh-icon-profile"
                    aria-hidden="true"
                    style="font-size: 28px; margin-bottom: 0.02rem"
                ></i>
                <span class="welcome-text">Welcome {{ userData.name }}</span>
            </button>
        </div>
    </div>
    <div *ngIf="!checkRouter()">
        <div class="blank-space"></div>
    </div>
</div>
<mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="setUserDataPath()">User Data</button>
    <button mat-menu-item (click)="setOrderHistoryPath()">Order History</button>
</mat-menu>
