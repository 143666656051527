import { createAction, props } from "@ngrx/store";
import { ProductAdviser } from "../../model/product-adviser.interface";

export const patch = createAction(
    "[Product Adviser] Patch Value",
    props<{ payload: Partial<ProductAdviser> }>()
);

export const changeValidationStatus = createAction(
    "[Product Adviser] Change Validation Status",
    props<{ isValid: boolean }>()
);

export const ProductAdviserActions = {
    patch,
    changeValidationStatus,
};
