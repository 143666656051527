import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "../auth/common.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private commonService: CommonService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const otp = localStorage.getItem("otp");
        const userId = localStorage.getItem("userId");
        if (otp && userId) {
            let clonedRequest = request.clone({
                setHeaders: {
                    OTP: otp,
                    "User-Id": userId,
                },
            });
            return next.handle(clonedRequest).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.error instanceof ErrorEvent) {
                        console.log("instanceof ErrorEvent");
                    } else {
                        if (error.status === 403) {
                            this.commonService.logout();
                            this.router.navigateByUrl("/steps-form/register-sign-in");
                        }
                    }
                    return throwError(error);
                })
            );
        } else return next.handle(request);
    }
}
