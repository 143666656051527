import { Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AppConfigService {
    private config: any;

    constructor(private httpClient: HttpClient) {}

    public loadConfig() {
        return firstValueFrom(this.httpClient.get("./assets/config/config.json"))
            .then((config: any) => {
                this.config = config;
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    public getBasePath(): string {
        return this.config?.basePath;
    }

    public isProd(): boolean {
        return this.config?.production;
    }
}

export const PROD = new InjectionToken<string>("production");
