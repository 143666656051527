<div class="container" style="margin-top: 1rem">
    <span style="font-family: var(--font-family-text); color: var(--lh-dark-blue)">
        Do you have general questions?
        <a href="mailto:apipwebshop@lht.dlh.de" style="text-decoration: underline"
            >Contact the APIP Team</a
        >.
    </span>
</div>
<nav class="breadcrumb-container">
    <div class="container">
        <span><a href="https://www.lufthansa-technik.com/en" target="_blank">Home</a></span>
        <i class="lh-icon lh-icon-arrow-next"></i>
        <span
            ><a href="https://www.lufthansa-technik.com/en/leasing-trading" target="_blank"
                >Leasing &amp; Trading</a
            ></span
        >
        <i class="lh-icon lh-icon-arrow-next"></i>
        <span
            ><a
                href="https://www.lufthansa-technik.com/en/aircraft-production-inspection"
                target="_blank"
                >Aircraft Production Inspection Program</a
            ></span
        >
    </div>
</nav>
<footer class="footer">
    <div class="footer-content">
        <div class="info__container-box">
            <div class="container">
                <hr />
                <div class="extra__info">
                    <div class="footer-menu">
                        <ul class="f-menu" style="height: 24px; margin: 20px 0">
                            <li>
                                <a
                                    href="https://www.lufthansa-technik.com/en/career"
                                    target="_blank"
                                    >Career</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://www.lufthansa-technik.com/en/imprint"
                                    target="_blank"
                                    >Imprint</a
                                >
                            </li>
                            <li>
                                <a href="/privacy-policy-form" target="_blank">Privacy Policy</a>
                            </li>
                            <li>
                                <a
                                    href="https://www.lufthansa-technik.com/en/legal-terms"
                                    target="_blank"
                                    >Legal Terms</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="container footer-bottom__info">
            <div class="footer__copyright">
                <p>&copy; Copyright Lufthansa Technik</p>
            </div>
            <span class="footer__info-menu">
                <span
                    ><a
                        class="company__elements"
                        href="https://www.lufthansagroup.com/en/home"
                        target="_blank"
                        >Lufthansa Group</a
                    ></span
                >
                <span
                    ><a
                        class="company__elements"
                        href="https://www.lufthansa-technik.com/en"
                        target="_blank"
                        >Lufthansa Technik</a
                    ></span
                >
            </span>
        </div>
        <div class="row-spacer"></div>
    </div>
</footer>
