/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Aircraft model.
 */
export interface Aircraft { 
    id: number;
    manufacturer: string;
    model: string;
    sellable: boolean;
}

