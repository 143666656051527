import { Component } from "@angular/core";

@Component({
    selector: "lht-full-layout",
    templateUrl: "./full-layout.component.html",
    styleUrls: ["./full-layout.component.scss"],
})
export class FullLayoutComponent {
    appName = "angular-sample";

    constructor() {}

    logout() {}
}
