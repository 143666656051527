import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = request.url;
        const method = request.method;
        if (environment.remoteHost && url.indexOf("http") === -1) {
            const remoteReq = request.clone({
                url: `${environment.remoteHost}${request.url}`,
            });
            return next.handle(remoteReq);
        }
        const req = request.clone({
            method: `${method}`,
            url: `${url}`,
        });
        return next.handle(req); // fallback in case url isn't caught
    }

    private matchUrl(url: string, requestedUrl: string) {
        return url.indexOf(`${requestedUrl}`) >= 0;
    }
}
