import { ProductSelectionGroup } from "../../model/product-selection.model";
import { Action, createReducer, on } from "@ngrx/store";
import { ProductSelectionActions } from "./product-selection.actions";
import { ProductSelection } from "../../model/product-selection.interface";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export const PRODUCT_SELECTION_FEATURE_KEY = "productSelection";

export interface ProductSelectionState extends EntityState<ProductSelection> {
    data: ProductSelection;
    isValid: boolean;
}

export interface ProductSelectionStatePartial {
    readonly [PRODUCT_SELECTION_FEATURE_KEY]: ProductSelectionState;
}

export const adapter: EntityAdapter<ProductSelection> = createEntityAdapter<ProductSelection>();
export const initialState: ProductSelectionState = adapter.getInitialState(
    new ProductSelectionGroup()
);

const reducer = createReducer(
    initialState,
    on(
        ProductSelectionActions.patch,
        (
            state: ProductSelectionState,
            action: ReturnType<typeof ProductSelectionActions.patch>
        ) => ({
            ...state,
            data: { ...state.data, ...action.payload },
        })
    ),
    on(
        ProductSelectionActions.changeValidationStatus,
        (
            state: ProductSelectionState,
            { isValid }: ReturnType<typeof ProductSelectionActions.changeValidationStatus>
        ) => ({
            ...state,
            isValid,
        })
    )
);

export function productSelectionReducer(state: ProductSelectionState, action: Action) {
    return reducer(state, action);
}

export const selectProductSelectionGroupData = (state: ProductSelectionState) => state.data;
export const selectProductSelectionGroupIsValid = (state: ProductSelectionState) => state.isValid;
