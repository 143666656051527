import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-privacy-notice-form",
    templateUrl: "./privacy-notice-form.component.html",
    styleUrls: ["./privacy-notice-form.component.scss"],
})
export class PrivacyNoticeFormComponent {
    constructor(private router: Router) {}
    goToProductAdvisor() {
        this.router.navigateByUrl("/steps-form/product-adviser");
    }
}
