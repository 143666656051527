import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-steps-form",
    templateUrl: "./steps-form.component.html",
    styleUrls: ["./steps-form.component.scss"],
})
export class StepsFormComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    onActivate(event) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
}
