import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule as NgrxStoreModule } from "@ngrx/store";

import { ActionResolver } from "./action.resolver";
import { ProductAdviserModule } from "./steps-form/product-adviser.module";
import { environment } from "@env/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { routerReducer, RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { ProductSelectionModule } from "@state/steps-form/product-selection.module";

@NgModule({
    imports: [
        CommonModule,
        NgrxStoreModule.forRoot(
            { router: routerReducer },
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictActionSerializability: false,
                    strictStateSerializability: true,
                },
            }
        ),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal,
        }),
        EffectsModule.forRoot([]),
        ProductAdviserModule,
        ProductSelectionModule,
    ],
    providers: [ActionResolver],
})
export class StoreModule {}
