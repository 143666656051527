import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsConditionsComponent } from "./terms-conditions.component";
import { SharedModule } from "@shared/shared.module";

@NgModule({
    declarations: [TermsConditionsComponent],
    imports: [CommonModule, SharedModule],
})
export class TermsConditionsModule {}
