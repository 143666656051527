import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule as NgrxStoreModule } from "@ngrx/store";
import { PRODUCT_ADVISER_FEATURE_KEY, productAdviserReducer } from "./product-adviser.reducer";

@NgModule({
    imports: [
        CommonModule,
        NgrxStoreModule.forFeature(PRODUCT_ADVISER_FEATURE_KEY, productAdviserReducer),
    ],
})
export class ProductAdviserModule {}
