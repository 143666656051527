<header class="header">
    <div class="container">
        <div class="row">
            <div class="pull-right">
                <a href="https://www.lufthansa-technik.com/web/lht">
                    <img src="../../../assets/lht-layout-full-page/LHT_Logo.png" alt="LHT Logo" />
                </a>
            </div>
        </div>
    </div>
</header>
<router-outlet></router-outlet>
