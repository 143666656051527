/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Service model.
 */
export interface Service { 
    id?: number;
    sections?: string;
    name?: string;
    price?: number;
    serviceLevel?: number;
    isPackage?: boolean;
    productPartName?: string;
    locations?: string;
    reference?: string;
    inclusion?: string;
    exclusion?: string;
    serviceLevelDef?: string;
    priceUsd?: number;
    tooltip?: string;
}

