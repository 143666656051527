/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Create order request object.
 */
export interface CreateOrderRequest { 
    quantity: number;
    startDate: string;
    aircraftId?: number;
    serviceIds?: Array<number>;
    deliveryAddressId?: number;
    userId: number;
    deliveryAndAssemblyLineIds?: Array<string>;
    deliveryServiceId?: number;
    finalAssemblyLineId?: number;
    currency?: string;
    additionalRemarks?: string;
    voucherCode?: string;
}

