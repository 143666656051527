/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Address Model
 */
export interface AddressCreateRequest { 
    street: string;
    city: string;
    country: string;
    zip: string;
    invoiceEmail: string;
    userId: number;
    addressLine2: string;
    stateProvinceRegion: string;
    company: string;
}

