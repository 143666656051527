import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-popup",
    templateUrl: "./popup.component.html",
    styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit {
    constructor(
        private dialog: MatDialogRef<PopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: String[]
    ) {}

    ngOnInit(): void {}
}
