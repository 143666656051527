import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-terms-conditions",
    templateUrl: "./terms-conditions.component.html",
    styleUrls: ["./terms-conditions.component.scss"],
})
export class TermsConditionsComponent {
    constructor(private router: Router) {}
    goToProductAdvisor() {
        this.router.navigateByUrl("/steps-form/product-adviser");
    }
}
