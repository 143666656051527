import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PopupComponent } from "../popup/popup.component";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root",
})
export class PopupService {
    public dl: MatDialogRef<PopupComponent>;
    constructor() {}

    open(
        dialog: MatDialog,
        form: FormGroup,
        map: Map<string, string>,
        custom_msg: string | null = null
    ) {
        if (this.dl) return;
        let errors: String[] = [];
        Object.entries(form.controls).forEach(([key, control]) => {
            for (let error in control.errors) {
                if (error == "required") {
                    errors.push(`${map.get(key)} field is required`);
                } else if (error == "email") {
                    errors.push(`Please use the right format for email`);
                } else if (error == "minlength") {
                    errors.push(`Selecting both checkboxes is required`);
                } else {
                    errors.push(error);
                }
            }
        });

        if (custom_msg) {
            errors.push(custom_msg);
        }

        this.dl = dialog.open(PopupComponent, {
            disableClose: true,
            hasBackdrop: false,
            scrollStrategy: new NoopScrollStrategy(),
            width: "25%",
            enterAnimationDuration: "1000ms",
            exitAnimationDuration: "4000ms",
            position: { bottom: "30px", right: "30px" },
            data: errors,
        });

        setTimeout(() => {
            this.dl.close();
            this.dl = undefined;
        }, 4000);
    }
}
