import { ProductAdviser } from "./product-adviser.interface";

export class ProductAdviserGroup {
    data = {
        aircraft: "",
        quantity: null,
        locations: [],
        start_date: "",
        currency: "eur",
    } as ProductAdviser;
    isValid = false;
}
