/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SpecialOrder { 
    aircraftModel: Array<string>;
    quantity: number;
    locations: Array<string>;
    startDate: string;
    userId: number;
}

