import { NgModule } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
    faAddressCard,
    faAngleLeft,
    faBaby,
    faBan,
    faHeart,
    faHome,
    faPencilAlt,
    faPlane,
    faSave,
    faSignInAlt,
    faTimesCircle,
    faUser,
    faEnvelope,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";

@NgModule({
    imports: [FontAwesomeModule],
    exports: [FontAwesomeModule],
    declarations: [],
    providers: [],
})
export class FontAwesomeIconModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faHome,
            faBan,
            faSave,
            faAddressCard,
            faUser,
            faHeart,
            faTimesCircle,
            faPencilAlt,
            faSignInAlt,
            faAngleLeft,
            faPlane,
            faBaby,
            faEnvelope,
            faPhone
        );
    }
}
