import { Component, OnInit } from "@angular/core";
import { faBell } from "@fortawesome/free-regular-svg-icons";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    // faLinkedin = faLinkedin;
    // faInstagram = faInstagram;
    // faYoutube = faYoutube;
    // faTwitter = faTwitter;
    // faFacebook = faFacebook;
    faTest = faBell;

    constructor() {}

    ngOnInit(): void {}
}
