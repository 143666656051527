export * from './address.service';
import { AddressService } from './address.service';
export * from './aircraft.service';
import { AircraftService } from './aircraft.service';
export * from './aircraftfacility.service';
import { AircraftfacilityService } from './aircraftfacility.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './discount.service';
import { DiscountService } from './discount.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './priceException.service';
import { PriceExceptionService } from './priceException.service';
export * from './service.service';
import { ServiceService } from './service.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './voucher.service';
import { VoucherService } from './voucher.service';
export const APIS = [AddressService, AircraftService, AircraftfacilityService, DefaultService, DiscountService, OrderService, PriceExceptionService, ServiceService, UserService, VoucherService];
