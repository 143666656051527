import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SpecialOrder, UserData } from "../../target";
import { ProductSelectionState } from "@state/steps-form/product-selection.reducer";
import { Store } from "@ngrx/store";
import { ProductSelection } from "../model/product-selection.interface";
import { ProductAdviserState } from "@state/steps-form/product-adviser.reducer";
import { ProductAdviser } from "../model/product-adviser.interface";
import * as fromStepForm from "@state/steps-form";
import { take } from "rxjs/operators";
import { ProductAdviserActions } from "@state/steps-form/product-adviser.actions";
import { ProductSelectionActions } from "@state/steps-form/product-selection.actions";

@Injectable({
    providedIn: "root",
})
export class CommonService {
    otp: any;
    expirationTime: string;
    productAdvisor: ProductAdviser = {
        aircraft: "",
        currency: "",
        locations: [],
        quantity: null,
        start_date: "",
    };
    productSelection: ProductSelection = {
        assemblyLines: "",
        deliveries: "",
        inspections: "",
        sections: [],
    };
    changed: boolean = false;
    onFirstRun: boolean = true;

    constructor(
        private router: Router,
        private store: Store<{
            productSelection: ProductSelectionState;
            productAdviser: ProductAdviserState;
        }>
    ) {}

    persistIfChanged() {
        this.detectChanges();
        if (this.changed) {
            this.onFirstRun = false;
            localStorage.setItem("productAdvisor", JSON.stringify(this.productAdvisor));
            localStorage.setItem("productSelection", JSON.stringify(this.productSelection));
        }

        this.changed = false;
    }

    deleteIncompleteOrder() {
        localStorage.removeItem("productAdvisor");
        localStorage.removeItem("productSelection");
    }

    continueOrder() {
        let productAdvisor: ProductAdviser = JSON.parse(localStorage.getItem("productAdvisor"));
        let productSelection: ProductSelection = JSON.parse(
            localStorage.getItem("productSelection")
        );
        if ((productSelection || productAdvisor) && this.onFirstRun) {
            if (
                confirm(
                    "Welcome back to the Lufthansa Technik APIP webshop. Would you like to continue with your previous order?"
                )
            ) {
                this.store
                    .select(fromStepForm.selectProductAdviserGroupData)
                    .pipe(take(1))
                    .subscribe(() => {
                        let pA: Partial<ProductAdviser> = productAdvisor;
                        this.store.dispatch(ProductAdviserActions.patch({ payload: pA }));
                    });
                this.store
                    .select(fromStepForm.selectProductSelectionGroupData)
                    .pipe(take(1))
                    .subscribe(() => {
                        let pA: Partial<ProductSelection> = productSelection;
                        this.store.dispatch(ProductSelectionActions.patch({ payload: pA }));
                    });
                this.onFirstRun = false;
            } else {
                this.onFirstRun = false;
            }
        }
    }

    getProductAdvisor() {
        let productAdvisor: ProductAdviser = JSON.parse(localStorage.getItem("productAdvisor"));
        return productAdvisor;
    }

    getProductSelection() {
        let productSelection: ProductSelection = JSON.parse(
            localStorage.getItem("productSelection")
        );
        return productSelection;
    }
    detectChanges() {
        this.store.select("productSelection").subscribe((res) => {
            let same = true;
            Object.entries(this.productSelection).forEach(([key, value]) => {
                if (this.productSelection[key] !== res.data[key]) {
                    if (key !== "sections") same = false;
                }
            });
            if (!same) {
                this.changed = true;
                this.productSelection = res.data;
            }
        });

        this.store.select("productAdviser").subscribe((res) => {
            let same = true;
            Object.entries(this.productAdvisor).forEach(([key, value]) => {
                if (this.productAdvisor[key] !== res.data[key]) {
                    same = false;
                }
            });
            if (!same) {
                this.changed = true;
                this.productAdvisor = res.data;
            }
        });
    }

    public isOTPinLSorExpired(): boolean {
        this.otp = localStorage.getItem("otp");
        this.expirationTime = localStorage.getItem("expirationTime");
        if (this.otp != null && Number(this.expirationTime) > Date.now()) {
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    logout() {
        localStorage.removeItem("otp");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem("userId");
        this.router.navigateByUrl("/steps-form/register-sign-in");
    }

    getUserId() {
        return Number(localStorage.getItem("userId"));
    }

    setUserData(response: UserData) {
        localStorage.setItem("name", response.name);
        localStorage.setItem("email", response.email);
        localStorage.setItem("phone", response.phone);
        localStorage.setItem("company", response.company);
    }

    getUserData() {
        let userData: UserData = {
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            phone: localStorage.getItem("phone"),
            company: localStorage.getItem("company"),
        };
        return userData;
    }

    getSpecialOrder() {
        const specialOrder = {} as SpecialOrder;
        const productAdvisor = this.getProductAdvisor();
        specialOrder.locations = productAdvisor.locations;
        specialOrder.quantity = productAdvisor.quantity;
        specialOrder.userId = this.getUserId();
        specialOrder.startDate = productAdvisor.start_date;
        specialOrder.aircraftModel = [productAdvisor.aircraft];
        return specialOrder;
    }

    getServices() {
        const productSelection = this.getProductSelection();
        let servicesDetailedOrder: any;
        servicesDetailedOrder = productSelection.sections;
        servicesDetailedOrder.push(productSelection.assemblyLines);
        servicesDetailedOrder.push(productSelection.deliveries);
        return servicesDetailedOrder;
    }

    emptyStore() {
        let productSelection: ProductSelection = {
            assemblyLines: "",
            deliveries: "",
            inspections: "",
            sections: [],
        };
        this.store
            .select(fromStepForm.selectProductSelectionGroupData)
            .pipe(take(1))
            .subscribe(() => {
                let pA: Partial<ProductSelection> = productSelection;
                localStorage.removeItem("productSelection");
                this.store.dispatch(ProductSelectionActions.patch({ payload: pA }));
            });

        let productAdviser: ProductAdviser = {
            aircraft: "",
            locations: [],
            quantity: null,
            start_date: "",
            currency: "eur",
        };
        this.store
            .select(fromStepForm.selectProductAdviserGroupData)
            .pipe(take(1))
            .subscribe(() => {
                let pA: Partial<ProductAdviser> = productAdviser;
                localStorage.removeItem("productAdvisor");
                this.store.dispatch(ProductAdviserActions.patch({ payload: pA }));
            });
    }
}
