import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { PrivacyNoticeFormComponent } from "./privacy-notice-form.component";

@NgModule({
    declarations: [PrivacyNoticeFormComponent],
    imports: [CommonModule, SharedModule],
})
export class PrivacyNoticeFormModule {}
