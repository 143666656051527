import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PopupService } from "@shared/popup.service";
import { Address, AddressCreateRequest, DefaultService, UserData } from "../../../../target";
import { CommonService } from "../../../auth/common.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
    constructor(
        private dialog: MatDialogRef<DialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: Address[],
        private popupService: PopupService,
        private dl: MatDialog,
        private addrService: DefaultService,
        private commonService: CommonService
    ) {}

    userData: UserData = this.commonService.getUserData();

    addressFG = new FormGroup({
        street: new FormControl(""),
        addressLine2: new FormControl(""),
        city: new FormControl(""),
        stateProvinceRegion: new FormControl(""),
        country: new FormControl(""),
        zip: new FormControl("", [Validators.required, Validators.maxLength(10)]),
        invoiceEmail: new FormControl(`${this.userData.email}`),
        company: new FormControl(`${this.userData.company}`),
    });

    on_submit() {
        this.addressFG.markAllAsTouched();
        let user_id = +localStorage.getItem("userId");
        let address: AddressCreateRequest = {
            street: "",
            city: "",
            country: "",
            zip: "",
            invoiceEmail: "",
            userId: user_id,
            addressLine2: "",
            stateProvinceRegion: "",
            company: "",
        };

        Object.entries(this.addressFG.controls).forEach(([key, control]) => {
            address[key] = control.value;
        });

        if (this.addressFG.valid) {
            this.#send_data(address, user_id);
        } else {
            this.showPopup();
        }
    }

    async #send_data(addr: AddressCreateRequest, user_id: number) {
        try {
            await firstValueFrom(this.addrService.createAddress(addr));
            let addresses = await firstValueFrom(this.addrService.getUsersAddresses(user_id));

            this.dialog.close({ data: addresses });
        } catch (err) {
            console.error("Error in sending/receiving address ", err);
        }
    }

    private showPopup() {
        let map = new Map<string, string>();
        map.set("street", "Address Line 1");
        map.set("city", "City");
        map.set("zip", "ZIP / Postal Code");
        map.set("country", "Country");
        map.set("invoiceEmail", "Invoice Email Address");
        map.set("addressLine2", "Address Line 2");
        map.set("stateProvinceRegion", "State / Province / Region");
        map.set("company", "Company");
        this.popupService.open(this.dl, this.addressFG, map);
    }
}
