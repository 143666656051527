import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromStepForm from "../../+state/steps-form";
import { take } from "rxjs/operators";
import { ProductAdviserActions } from "@state/steps-form/product-adviser.actions";
import { ProductAdviser } from "../../model/product-adviser.interface";

@Component({
    selector: "app-form-wizard",
    templateUrl: "./form-wizard.component.html",
    styleUrls: ["./form-wizard.component.scss"],
})
export class FormWizardComponent implements OnInit {
    @Input() title: string;
    @Input() lastStep: boolean = false;
    @Input() showButton: boolean = true;
    @Input() showCurrency: boolean = false;
    @Input() is_regular: boolean = true;
    @Input() custom_btn_text: string = "Click";

    @Output() previousStepClicked = new EventEmitter();
    @Output() nextStepClicked = new EventEmitter();
    @Output() submitFormClicked = new EventEmitter();
    @Output() currencyChange = new EventEmitter<string>();
    @Output() apipSpeak = new EventEmitter();
    currency: string;

    goToPreviousStep() {
        this.previousStepClicked.emit();
    }

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .select(fromStepForm.selectProductAdviserGroupData)
            .pipe(take(1))
            .subscribe((productAdviser) => {
                this.currency = productAdviser.currency;
            });
    }

    goToNextStep() {
        this.nextStepClicked.emit();
    }

    submitForm() {
        this.submitFormClicked.emit();
    }

    onChange($event: any) {
        this.store
            .select(fromStepForm.selectProductAdviserGroupData)
            .pipe(take(1))
            .subscribe((productAdviser) => {
                let pA: Partial<ProductAdviser> = {
                    aircraft: productAdviser.aircraft,
                    quantity: productAdviser.quantity,
                    locations: productAdviser.locations,
                    start_date: productAdviser.start_date,
                    currency: this.currency,
                };
                this.store.dispatch(ProductAdviserActions.patch({ payload: pA }));
            });
        this.currencyChange.emit(this.currency);
    }
    submitDetailedOrder() {
        this.apipSpeak.emit();
    }
    changeCurrencyUsd() {
        this.currency = "usd";
        this.onChange("usd");
    }
    changeCurrencyEur() {
        this.currency = "eur";
        this.onChange("eur");
    }

    convertCurrency() {
        if (this.currency === "eur") {
            return "EUR";
        } else {
            return "USD";
        }
    }
}
