import { ProductAdviserGroup } from "../../model/product-adviser.model";
import { Action, createReducer, on } from "@ngrx/store";
import { ProductAdviserActions } from "./product-adviser.actions";
import { ProductAdviser } from "../../model/product-adviser.interface";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export const PRODUCT_ADVISER_FEATURE_KEY = "productAdviser";

export interface ProductAdviserState extends EntityState<ProductAdviser> {
    data: ProductAdviser;
    isValid: boolean;
}

export interface ProductAdviserStatePartial {
    readonly [PRODUCT_ADVISER_FEATURE_KEY]: ProductAdviserState;
}

export const adapter: EntityAdapter<ProductAdviser> = createEntityAdapter<ProductAdviser>();
export const initialState: ProductAdviserState = adapter.getInitialState(new ProductAdviserGroup());

const reducer = createReducer(
    initialState,
    on(
        ProductAdviserActions.patch,
        (state: ProductAdviserState, action: ReturnType<typeof ProductAdviserActions.patch>) => ({
            ...state,
            data: { ...state.data, ...action.payload },
        })
    ),
    on(
        ProductAdviserActions.changeValidationStatus,
        (
            state: ProductAdviserState,
            { isValid }: ReturnType<typeof ProductAdviserActions.changeValidationStatus>
        ) => ({
            ...state,
            isValid,
        })
    )
);

export function productAdviserReducer(state: ProductAdviserState, action: Action) {
    return reducer(state, action);
}

export const selectProductAdviserGroupData = (state: ProductAdviserState) => state.data;
export const selectProductAdviserGroupIsValid = (state: ProductAdviserState) => state.isValid;
