/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Update aircraft request object.
 */
export interface UpdateAircraftRequest { 
    registration?: string;
    aircraftStatus?: UpdateAircraftRequest.AircraftStatusEnum;
    ownerId?: string;
}
export namespace UpdateAircraftRequest {
    export type AircraftStatusEnum = 'ACTIVE' | 'INACTIVE';
    export const AircraftStatusEnum = {
        Active: 'ACTIVE' as AircraftStatusEnum,
        Inactive: 'INACTIVE' as AircraftStatusEnum
    };
}


