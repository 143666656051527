<header class="header">
    <div class="container">
        <div class="row" style="margin: 0">
            <div class="pull-right">
                <a (click)="goToProductAdvisor()">
                    <img src="../../../assets/lht-layout-full-page/LHT_Logo.png" alt="LHT Logo" />
                </a>
            </div>
        </div>
    </div>
</header>
<body>
    <div class="privacy-notice">
        <app-form-wizard [title]="'Privacy Policy: Data Protection Statement'" [showButton]="false">
            <div class="container">
                <h5><strong>1. Controller</strong></h5>
                <p>
                    We, Lufthansa Technik AG , Weg beim Jaeger 193, 22335 Hamburg, Germany,
                    (hereinafter also referred to as "we" or "us"), hereby provide you with
                    information on the processing of your personal data collected directly from you
                    (e.g. when you enter into an agreement with us or when you visit our APIP
                    webshop https://app.lufthansa-technik.com/apipwebshop ("webshop")).
                </p>
                <br />
                <p>
                    If you have further questions on the subject of data protection in connection
                    with our webshop or the services offered on it, please contact either
                    <a href="mailto:apipwebshop@lht.dlh.de">apipwebshop@lht.dlh.de</a> for generic
                    APIP enquiries or our data protection officer:
                </p>
                <br />
                <p>
                    <strong> Deutsche Lufthansa AG </strong>
                    <br />
                    <strong> Data Protection Officer </strong>
                    <br />
                    <strong> Airportring </strong>
                    <br />
                    <strong> 60546 Frankfurt </strong>
                </p>
                <br />
                <p>or by e-mail to: <a href="mailto:datenschutz@dlh.de">datenschutz@dlh.de</a></p>
                <br />
                <p>If contact is made by e-mail, communication is unencrypted.</p>
                <br />
                <h5>
                    <strong
                        >2. Scope and purpose of and legal basis for the processing of personal
                        data</strong
                    >
                </h5>
                <br />
                <p>
                    We collect and use personal data directly from our users in the following
                    situations:
                </p>
                <br />
                <h5><strong>2.1. Provision of the webshop and creation of log files</strong></h5>
                <br />
                <p>
                    Whenever users visit our webshop, our system automatically records data and
                    information from the computer system used to call our webshop. The following
                    data ("technical information") is collected in the process:
                </p>
                <br />
                <ul>
                    <li>Information on the type of browser used and its version</li>
                    <li>The user's operating system</li>
                    <li>The user's Internet service provider</li>
                    <li>The user's IP address</li>
                    <li>The date and time of access</li>
                    <li>Websites from which the user's system accesses our webshop</li>
                    <li>Websites that the user's system calls from our webshop</li>
                </ul>
                <br />
                <p>
                    We collect and use this technical information for purposes of (network) security
                    (such as to combat cyberattacks), marketing, to enter into contracted services,
                    and to be able to better understand our users' needs, as well as to continuously
                    improve our webshop and to enable us to deliver the webshop to the user's
                    computer system.
                </p>
                <br />
                <p>
                    The log files contain IP addresses or other data that may, in some cases, be
                    able to be associated to a user. That may be the case, for example, if personal
                    data is contained in the link to the webshop from which the user enters our
                    webshop, or the link to the webshop to which the user switches.
                </p>
                <br />
                <p>
                    The data is likewise stored in our system's log files. This data is not stored
                    together with other personal data of the user.
                </p>
                <br />
                <p>
                    The data is stored in log files in order to ensure that the webshop functions
                    properly. This data also helps us optimize the webshop and ensure the security
                    of our IT systems. It may also be analyzed for marketing and sales purposes in
                    this connection, e.g. user entries made in the checkout tunnel, which ultimately
                    do not lead to a confirmed order, may be reviewed by APIP sales in order to
                    contact the customer and provide assistance.
                </p>
                <br />
                <p>
                    The legal basis for the temporary storage of data and log files is Article
                    6(1)(f) of the EU General Data Protection Regulation (GDPR).
                </p>
                <br />
                <h5><strong>2.1.1. Use of cookies</strong></h5>
                <br />
                <p>
                    Our webshop uses cookies. Cookies are text files that are stored in your web
                    browser. If you visit a website, a cookie may be stored on your operating
                    system. This cookie contains a characteristic string that allows your browser to
                    be identified unambiguously if you visit the website again. There are two types
                    of cookies:
                </p>
                <br />
                <ul>
                    <li>
                        Session cookies, which are temporary and are erased when you close your
                        browser at the end of your surfing session. The next time you visit our
                        website it will not recognize you and will treat you as a new visitor.
                    </li>
                    <li>
                        Persistent cookies, which stay in one of your browser's subfolders until you
                        delete them manually or your browser deletes them based on the duration
                        period contained within the persistent cookie's file.
                    </li>
                </ul>
                <br />
                <p>
                    Cookies are stored on your computer system and are transferred to our site. That
                    means that you, the user, have full control over the use of cookies. You can
                    disable or restrict the transfer of cookies by changing the settings in your web
                    browser. Cookies that have already been stored can be deleted at any time. This
                    process can be automated. If cookies are disabled for our website, you may no
                    longer be able to use all of the website's features in full.
                </p>
                <br />
                <p>
                    We use the following categories of cookies to provide you with the following
                    functionality or information:
                </p>
                <br />
                <ul>
                    <li>
                        Essential: These cookies are necessary to run the core functionalities of
                        this website. We use these cookies for security related functions /
                        authentication as well as storing your order until you submit it to us.
                    </li>
                    <li>Functional: We do not use any cookies of this category.</li>
                    <li>Marketing: We do not use any cookies of this category.</li>
                    <li>Personalization: We do not use any cookies of this category.</li>
                </ul>
                <br />
                <p>
                    The legal basis for storing essential cookies in your webbrowser is Article
                    6(1)(f) of the GDPR. For all other cookies we would ask for your consent, i.e.
                    the legal basis is Article 6(1)(a) of the GDPR.
                </p>
                <br />
                <p>
                    By deleting the cookies you are able to delete the stored information of our
                    website at any time.
                </p>
                <br />
                <h5><strong>2.2. Use of the services offered on our webshop</strong></h5>
                <br />
                <p>
                    We offer a number of APIP services on our webshop. To provide those services, we
                    must collect and process the following personal data from our users: first and
                    surname, e-mail address, company name, phone number, invoice address, and
                    desired service elements.
                </p>
                <br />
                <p>
                    We may contact you regarding your selections, orders and requests made at our
                    website.
                </p>
                <br />
                <p>The legal basis for this is Article 6(1)(f) of the GDPR.</p>
                <br />
                <h5><strong>2.3. Our legitimate interests in processing personal data</strong></h5>
                <br />
                <p>
                    Where Article 6(1)(f) of the GDPR is the legal basis for processing, our
                    legitimate interests – apart from the purposes stated above – are:
                </p>
                <br />
                <ul>
                    <li>Protecting the company against material or non-material damage</li>
                    <li>Making our products and services more professional</li>
                    <li>Offering our products and services for purchase online</li>
                    <li>Optimizing (controlling and minimizing) costs</li>
                </ul>
                <br />
                <h5><strong>2.4. Other processing obligations</strong></h5>
                <br />
                <p>
                    Where we are obligated by law, we process personal data to comply with retention
                    obligations under commercial law or to meet statutory requirements relating to
                    security (such as those pursuant to Section 7 of the German Aviation Security
                    Act (LuftSiG)). You can find more information on retention periods in the
                    section "Duration of data processing" below.
                </p>
                <br />
                <h5><strong>3. Duration of data processing</strong></h5>
                <br />
                <p>
                    Your personal data is erased as soon as it is no longer required for the
                    specified purposes. It may be the case that personal data is retained for the
                    period of time in which claims may be asserted against Lufthansa Technik AG. In
                    addition, personal data is stored if and for as long as Lufthansa Technik AG is
                    obligated by law to do so. Such documentation and retention obligations are
                    stipulated by the German Commercial Code (HGB), the German Fiscal Code (AO) and
                    the German Money Laundering Act (GwG), among others. Under this legislation,
                    storage may be required for up to ten years.
                </p>
                <br />
                <h5>
                    <strong>4. Right to object in accordance with Article 21 of the GDPR</strong>
                </h5>
                <br />
                <p>
                    You have the right to object, on grounds relating to your particular situation,
                    at any time to the processing of personal data concerning you which is based on
                    Article 6(1)(e) or (f) of the GDPR, including profiling based on those
                    provisions.
                </p>
                <br />
                <p>
                    The controller shall no longer process the personal data concerning you unless
                    it demonstrates compelling legitimate grounds for the processing which override
                    your interests, rights and freedoms, or for the establishment, exercise or
                    defense of legal claims.
                </p>
                <br />
                <p>
                    Where personal data concerning you is processed for direct marketing purposes,
                    you have the right to object at any time to the processing of personal data
                    concerning you for such marketing, which includes profiling to the extent that
                    it is related to such direct marketing.
                </p>
                <br />
                <p>
                    If you object to processing for direct marketing purposes, the personal data
                    concerning you will no longer be processed for such purposes.
                </p>
                <br />
                <h5><strong>5. Disclosure of personal data to third party</strong></h5>
                <br />
                <p>
                    We may be required to forward your personal data to third parties within or
                    outside the Lufthansa Group in order to be able to offer you our products and
                    services on the basis of our contractual obligations or our legitimate
                    interests. These recipients can be categorized as follows:
                </p>
                <br />
                <ul>
                    <li>Service providers</li>
                    <li>Marketing</li>
                    <li>Airframe manufacturers</li>
                    <li>IT</li>
                    <li>Government bodies and authorities</li>
                    <li>Members of the Lufthansa Group</li>
                </ul>
                <br />
                <p>
                    In the process, personal data may be transferred to third countries or
                    international organizations. In order to protect you and your personal data,
                    there are suitable safeguards in such cases as stipulated by and in compliance
                    with statutory requirements (in particular the use of EU standard contractual
                    clauses) or there is an adequacy decision adopted by the EU Commission (Article
                    45 of the GDPR).
                </p>
                <br />
                <p>
                    You can find information on EU standard contractual clauses at
                    <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/publications/standard-contractual-clauses-controllers-and-processors_en"
                        >https://ec.europa.eu/info/law/law-topic/data-protection/publications/standard-contractual-clauses-controllers-and-processors_en</a
                    >. The EU Commission provides information on its adequacy decisions at [<a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu"
                        >https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu</a
                    >].
                </p>

                <p>
                    We are also obligated by law to provide personal data to German and
                    international authorities (Article 6 (1) (c) of the GDPR in conjunction with
                    local and international regulations and agreements).
                </p>
                <br />
                <h5><strong>6. Rights of data subjects</strong></h5>
                <br />
                <p>
                    Lufthansa Technik AG is committed to the fair and transparent processing of
                    data. We therefore believe it to be important that data subjects not only have
                    the right to object, but can also exercise the following rights if the relevant
                    legal requirements are fulfilled:
                </p>
                <br />
                <ul>
                    <li>
                        Right to access personal data and obtain information (Article 15 of the
                        GDPR)
                    </li>
                    <li>Right to rectification (Article 16 of the GDPR)</li>
                    <li>Right to erasure ("right to be forgotten") (Article 17 of the GDPR)</li>
                    <li>Right to restriction of processing (Article 18 of the GDPR)</li>
                    <li>Right to data portability (Article 20 of the GDPR)</li>
                </ul>
                <br />
                <p>
                    You can contact apipwebshop@lht.dlh.de by e-mail to exercise your rights. To
                    handle your request and for the purpose of identification, we note that we will
                    process your personal data in accordance with Article 6(1)(c) of the GDPR.
                </p>
                <br />
                <p>
                    You also have the right to lodge a complaint with a supervisory authority. The
                    supervisory authority responsible for Lufthansa Technik AG is:<br />
                </p>
                <p>
                    Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit<br />
                    (Hamburg Commissioner for Data Protection and Freedom of Information)<br />
                    Ludwig-Erhard-Str 22, 7. OG<br />
                    20459 Hamburg<br />
                    Germany<br />
                    Phone: +49 40 42854-4040<br />
                    Fax: +49 40 42854-4000<br />
                    E-mail:
                    <a href="mailto:mailbox@datenschutz.hamburg.de"
                        >mailbox@datenschutz.hamburg.de</a
                    >
                </p>
                <span style="font-family: var(--font-family-text); margin-top: 100px">
                    Last change: 12th of June, 2023
                </span>
                <br />
                <span style="font-family: var(--font-family-text); margin-top: 100px">
                    Find our Terms & Conditions
                    <a href="/terms-conditions" target="_blank">here</a>.
                </span>
            </div>
        </app-form-wizard>
    </div>
</body>
