/**
 * CRUD Demo
 * CRUD Demo
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderHistory { 
    orderType: string;
    orderDate: string;
    orderNumber: string;
    acType: string;
    acNumber: number;
    locations: Array<string>;
    services: Array<string>;
    startDate: string;
    price: number;
    currency: string;
    userId: number;
}

