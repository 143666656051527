<section>
    <div class="container">
        <div class="form-header">
            <div class="infostatic_top"></div>
            <div class="infostatic_main">
                <span class="letter-space">APIP Webshop</span>
            </div>
            <div class="infostatic_subline"></div>
            <div class="infostatic_copy flex-center">
                <span>{{ title }}</span>
                <div *ngIf="showCurrency">
                    <button
                        mat-flat-button
                        [matMenuTriggerFor]="appMenu"
                        class="lht-border"
                        id="currency-button"
                    >
                        <span class="lh-icon lh-icon-player-play" id="top-space"></span>
                        <span id="left-space">{{ convertCurrency() }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ng-content></ng-content>
    <div class="container">
        <div *ngIf="showButton" class="flex-column" style="margin-top: 7rem">
            <div *ngIf="is_regular">
                <div style="margin-bottom: 5px">
                    <a
                        type="submit"
                        id="apip-speak"
                        (click)="submitDetailedOrder()"
                        class="btn btn-secondary"
                        style="width: 300px"
                    >
                        Speak to APIP representative
                    </a>
                </div>
                <div style="margin-bottom: 5px">
                    <span id="or">or</span>
                </div>
            </div>
            <div class="flex-center">
                <a class="btn btn-secondary" style="width: 100px" (click)="goToPreviousStep()">
                    Back
                </a>
                <button
                    *ngIf="!lastStep && is_regular; else sumbitOrder"
                    type="submit"
                    class="btn btn-primary"
                    style="width: 300px; display: block"
                    id="continue-with-order"
                    (click)="goToNextStep()"
                >
                    Continue
                </button>
                <button
                    *ngIf="!lastStep && is_regular"
                    type="submit"
                    class="btn btn-primary"
                    style="width: 300px; display: none"
                    id="submit-req"
                    #submit_req
                >
                    Submit Request
                </button>
                <ng-template #sumbitOrder>
                    <button
                        type="submit"
                        class="btn btn-primary"
                        style="width: 300px"
                        (click)="submitForm()"
                        *ngIf="is_regular"
                    >
                        Submit order
                    </button>
                </ng-template>
                <button
                    *ngIf="!is_regular"
                    type="submit"
                    class="btn btn-primary"
                    style="width: 300px"
                    (click)="goToNextStep()"
                >
                    {{ this.custom_btn_text }}
                </button>
            </div>
        </div>
    </div>
</section>

<mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="changeCurrencyEur()">EUR</button>
    <button mat-menu-item (click)="changeCurrencyUsd()">USD</button>
</mat-menu>
