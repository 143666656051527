import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeIconModule } from "./font-awesome/font-awesome-icon.module";
import { FormWizardComponent } from "./form-wizard/form-wizard.component";
import { FormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    imports: [
        // Shared
        FontAwesomeIconModule,
        CommonModule,
        FormsModule,
        MatMenuModule,
        MatButtonModule,
    ],
    exports: [
        // Shared
        FontAwesomeIconModule,
        FormWizardComponent,
    ],
    providers: [],
    declarations: [FormWizardComponent],
})
export class SharedModule {}
