import { ProductSelection } from "./product-selection.interface";

export class ProductSelectionGroup {
    data = {
        inspections: "",
        sections: [],
        assemblyLines: "",
        deliveries: "",
    } as ProductSelection;
    isValid = false;
}
