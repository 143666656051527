<button class="close" mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon style="width: 5rem; height: 5rem; font-size: 2.5rem">close</mat-icon>
</button>
<div class="container">
    <form class="relative-center px-3" [formGroup]="addressFG" (ngSubmit)="on_submit()">
        <div class="form-row relative-center">
            <span>New Address</span>
        </div>
        <div class="form-row relative-center">
            <label for="company">Company</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="company"
                name="street"
                formControlName="company"
                required
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">Address Line 1 (e.g Street, Number)</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="street"
                name="street"
                formControlName="street"
                required
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">Address Line 2 (e.g P.O. Box, c/o, Building, Department)</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="addressLine2"
                name="addressLine2"
                formControlName="addressLine2"
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">City</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="city"
                name="city"
                formControlName="city"
                required
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">ZIP / Postal Code</label>
        </div>
        <div class="form-row form-selection zip">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="zip"
                name="zip"
                formControlName="zip"
                required
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">State / Province / Region</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="stateProvinceRegion"
                name="stateProvinceRegion"
                formControlName="stateProvinceRegion"
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">Country</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="country"
                name="country"
                formControlName="country"
                required
            />
        </div>
        <div class="form-row relative-center">
            <label for="street">Invoice Email Address</label>
        </div>
        <div class="form-row form-selection relative-center">
            <input
                class="lht-text-input lht-border font-blue"
                type="text"
                id="invoiceEmail"
                name="invoiceEmail"
                formControlName="invoiceEmail"
                required
            />
        </div>

        <div class="form-row form-selection relative-center pt-4">
            <button type="submit" class="btn btn-primary dialog-btn" style="margin-right: auto">
                Save
            </button>
            <button
                type="button"
                class="btn btn-secondary dialog-btn"
                style="margin-left: auto"
                mat-dialog-close
            >
                Close
            </button>
        </div>
    </form>
</div>
