import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "@shared/shared.module";
import { StoreModule } from "@state";
import { BASE_PATH } from "../target";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FullLayoutComponent } from "./layout/full-layout/full-layout.component";
import { SimpleLayoutComponent } from "./layout/simple-layout/simple-layout.component";
import { PopupComponent } from "./popup/popup.component";
import { AppConfigService, PROD } from "./services/app-config.service";
import { DialogComponent } from "./steps-form/contract-conclusion/dialog/dialog.component";
import { PrivacyNoticeFormModule } from "./privacy-notice-form/privacy-notice-form.module";
import { TermsConditionsModule } from "./terms-conditions/terms-conditions.module";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { TooltipModule } from "primeng/tooltip";
import { environment } from "@env/environment";
import { MockHttpInterceptor } from "./interceptor/mock-interceptor";
import { AuthInterceptor } from "./interceptor/auth.interceptor";

export function initializeApp(appConfigService: AppConfigService) {
    return () =>
        appConfigService.loadConfig().then(() => {
            if (environment.mock) {
                return Promise.resolve(true);
            }
        });
}

export function getBasePath(appConfig: AppConfigService) {
    return appConfig.getBasePath();
}

export function isProd(appConfig: AppConfigService) {
    return appConfig.isProd();
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        SimpleLayoutComponent,
        DialogComponent,
        PopupComponent,
    ],
    entryComponents: [DialogComponent],

    imports: [
        // API_MODULE,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        StoreModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        MatTableModule,
        PrivacyNoticeFormModule,
        TermsConditionsModule,
        MatIconModule,
        MatMenuModule,
        TooltipModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfigService],
            multi: true,
        },
        {
            provide: BASE_PATH,
            useFactory: getBasePath,
            deps: [AppConfigService],
            multi: false,
        },
        {
            provide: PROD,
            useFactory: isProd,
            deps: [AppConfigService],
            multi: false,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
