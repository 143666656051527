import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { SimpleLayoutComponent } from "./layout/simple-layout/simple-layout.component";
import { StepsFormComponent } from "./steps-form/steps-form.component";
import { PrivacyNoticeFormComponent } from "./privacy-notice-form/privacy-notice-form.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";

const routes: Routes = [
    {
        path: "",
        redirectTo: "steps-form",
        pathMatch: "full",
    },
    {
        path: "",
        component: StepsFormComponent,
        children: [
            {
                path: "steps-form",
                loadChildren: () =>
                    import("./steps-form/steps-form.module").then((m) => m.StepsFormModule),
            },
        ],
    },
    {
        path: "pages",
        component: SimpleLayoutComponent,
        data: {
            title: "Pages",
        },
        children: [
            {
                path: "",
                loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
            },
        ],
    },
    {
        path: "privacy-policy-form",
        component: PrivacyNoticeFormComponent,
    },
    {
        path: "terms-conditions",
        component: TermsConditionsComponent,
    },
    {
        path: "**",
        redirectTo: "pages/404",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
