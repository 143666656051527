import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../auth/common.service";
import { UserData } from "../../../target";
import { Router } from "@angular/router";
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    constructor(private commonService: CommonService, private router: Router) {}
    userData: UserData;
    ngOnInit(): void {
        this.userData = this.commonService.getUserData();
    }
    setUserDataPath() {
        this.router.navigateByUrl("/steps-form/user-data");
    }
    setOrderHistoryPath() {
        this.router.navigateByUrl("/steps-form/order-history-table");
    }
    checkRouter() {
        if (
            this.router.url == "/steps-form/register-sign-in" ||
            this.router.url == "/steps-form/password"
        ) {
            return false;
        }
        return true;
    }
    goToProductAdvisor() {
        this.router.navigateByUrl("/steps-form/product-adviser");
    }
}
