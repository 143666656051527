<header class="header">
    <div class="container">
        <div class="row" style="margin: 0">
            <div class="pull-right">
                <a (click)="goToProductAdvisor()">
                    <img src="../../../assets/lht-layout-full-page/LHT_Logo.png" alt="LHT Logo" />
                </a>
            </div>
        </div>
    </div>
</header>
<body>
    <div class="terms-conditions">
        <app-form-wizard
            [title]="
                'General Terms and Conditions for the Lufthansa Technik AG APIP Webshop and the Performance of APIP Services'
            "
            [showButton]="false"
        >
            <div class="container">
                <div class="row">
                    <div class="col-sm-1">
                        <strong>1</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Definitions</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Account</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            An account for the exclusive use of the Customer for the APIP Webshop
                            with one main point of contact
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Affiliates</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Any company or entity (i) directly or indirectly controlled by LHT, for
                            which purpose control means either ownership of more than fifty per cent
                            (50%) of the voting rights and/or share capital (or equivalent right of
                            ownership) of such companies or entities, or power to direct its
                            policies and management, whether by contract or otherwise; or (ii) by
                            which LHT is controlled in the aforementioned manner; or (iii) which are
                            controlled by companies or entities in the aforementioned manner while
                            LHT is controlled in the aforementioned manner by the same company or
                            entity.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Aircraft</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            The aircraft that is manufactured by the Manufacturer for the Customer
                            and for which the APIP Services are provided
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>APIP</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>Aircraft Production Inspection Program</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>APIP Services</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            The aircraft production inspection services agreed between the Parties
                            under a Customer Agreement and as further described in cl. 8 below
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>APIP Webshop</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>The B2B online platform for the sale of APIP Services</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Assistants</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Affiliates, subcontractors, IT service providers, temporary workers,
                            advisers and consultants used by LHT under or in connection with the
                            Customer Agreement.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Authority</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Either the approving authority of the Aircraft or the approving
                            authority of the maintenance organization, production organization,
                            design organization and/or the continuing airworthiness management
                            organization.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Beneficiary</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Any Party (including, for the avoidance of doubt, third parties)
                            receiving Confidential Information from the other Party in connection
                            with the Customer Agreement.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Confidential Information</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Any information pertaining to the Customer Agreement including, but not
                            limited to, the contractual document itself as well as the individual
                            provisions contained therein and the content of the negotiations leading
                            up to the Customer Agreement. This includes, but is not limited to, any
                            business, technical, commercial and strategic data – comprising any and
                            all such information in oral or visual form – as well as prices for
                            materials and services, the scope of services offered, legal provisions,
                            performance dates and man-hours needed.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Customer</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Any legal entity that legitimately uses the APIP Webshop in accordance
                            with these Terms and Conditions and that may conclude Customer
                            Agreements with LHT
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Customer Agreement</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            An agreement between LHT and the Customer for the provision of APIP
                            Services
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Excluded Taxes</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            (a) Taxes based on income, capital gains, net worth or property payable
                            by LHT to any tax authority in Germany; or (b) Taxes for which LHT
                            becomes liable by reason of its wilful misconduct or gross negligence.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Intellectual Property Rights</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            All intellectual property rights including, but not limited to, patents,
                            utility models, designs, copyrights, trademarks, trade names, know-how
                            (including, but not limited to, documents, sketches, data and other
                            information) and trade secrets and all renewals and extensions thereto
                            and any other intellectual property rights contained in and/or created
                            in the course of rendering the services or delivering Material, other
                            products and processes.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>LHT</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>Lufthansa Technik AG</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Manufacturer</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>The manufacturer of the Aircraft</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>NAA</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            The National Aviation Authority of the country of the Aircraft registry
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>OEM</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>Original Equipment Manufacturer</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Taxes</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            Any and all present and future taxes, withholdings, levies, assessments,
                            imposts, fees and other governmental charges of all kinds (including,
                            without limitation, any value added or similar tax and any stamp,
                            documentary, registration or similar tax) and any amount treated as such
                            whenever created or imposed and whether of the government of Germany or
                            elsewhere and whether imposed by a local, municipal, governmental,
                            state, federal or other body including, without limitation, all fines,
                            penalties, costs, charges and expenses payable in connection with any
                            failure to pay or delay in paying the same (except and to the extent
                            that any such fines, penalties, costs, charges and expenses arise as a
                            result of acts or omissions or delay of LHT) but not “Excluded Taxes”
                            and references to “Taxes” shall be construed accordingly.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 text_padding_start">
                        <strong>Terms and Conditions</strong>
                    </div>
                    <div class="col-sm-8">
                        <p>
                            These General Terms and Conditions for the LHT APIP Webshop and the
                            performance of APIP Services
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>2</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Exclusive Validity of Terms and Conditions</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>2.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT operates the APIP Webshop and offers the APIP Services on the APIP
                            Webshop. These Terms and Conditions shall exclusively apply to the
                            Customer’s registration and use of APIP Webshop. Subject to clause 5
                            below, these Terms and Conditions shall also apply exclusively to the
                            performance of the APIP Services. Deviating or supplementary terms and
                            conditions of the Customer shall only apply insofar as LHT has
                            explicitly agreed to them in textform.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>2.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            2.2 The APIP Services are directed exclusively to entrepreneurs (as
                            defined in Sec. 14 (1) of the German Civil Code, i. e. natural or legal
                            persons or partnerships with legal capacity acting, when entering into
                            the transaction in the exercise of their commercial, business or
                            professional activity), as well as legal entities and special funds
                            organized under public law. The Customer is required to confirm the
                            above upon registration on the APIP Webshop.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Registration on the APIP Webshop; Use of Account</strong></h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            For using the APIP Webshop and ordering APIP Services the Customer needs
                            to create an Account for the APIP Webshop. LHT can refuse the opening of
                            an account without giving any reason. There is no right to an admission
                            to or use of the APIP Webshop.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Only a duly authorized representative of the Customer who has sufficient
                            power of attorney for the registration and the conclusion of Customer
                            Agreements is entitled to create an Account for the relevant Customer.
                            LHT may request adequate evidence from the Customer’s representative to
                            prove his proper authorisation to act on behalf of the Customer.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            After the registration of the Customer has been completed, LHT will
                            verify whether the information provided meets its requirements. If all
                            requirements are met and all information has been submitted, LHT will at
                            its sole discretion activate the Account free of charge and will inform
                            the Customer by e-mail about the Account’s activation. The Account is
                            personal for the Customer and cannot be transferred to a third party.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            When registering on the APIP Webshop, the Customer must choose a safe
                            password. The Customer is not allowed to disclose this password and/or
                            any other login data to any unauthorized third party. The Customer shall
                            take appropriate measures to maintain confidentiality of said data and
                            to prevent the misuse of the Account by third parties. If the Customer
                            has any indication that the Account is being, was or is intended to be
                            misused by any third party, the Customer shall immediately notify LHT in
                            textform.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.5</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Account may only be used by the representative who completed the
                            registration and who is duly authorized to act for the Customer. Only
                            this representative is allowed to use the personal login data for the
                            Account.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.6</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If the provided information changes after registration, the Customer is
                            obliged to immediately inform LHT in textform about such changes. In
                            particular if the representative of the Customer changes or if the
                            representative’s power to act on behalf of the Customer no longer
                            exists, the Customer shall inform LHT about such change and shall
                            further ensure that a new representative is appointed that has
                            sufficient power of attorney to act and conclude Customer Agreements on
                            the Customer’s behalf.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.7</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer will not be obliged to order any APIP Services and to
                            conclude any Customer Agreement only due to its registration.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.8</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT is entitled to terminate a Customer’s Account to the APIP Webshop
                            where there are grounds to suspect that the Customer has materially
                            breached these Terms and Conditions. The Customer may avert such
                            measures if the Customer submits appropriate proof at its own expense.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.9</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer is entitled to terminate the Account at any time for
                            convenience by sending an e-mail to LHT. Upon receipt of such
                            termination notice, LHT will delete the Customer’s Account and inform
                            the Customer accordingly.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>3.10</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            For information on the processing of the Customer’s data, the Customer
                            may read LHT’s data protection information, which can be found under the
                            following
                            <a
                                href="https://www.lufthansa-technik.com/en/privacy-policy"
                                target="_blank"
                                >link</a
                            >.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>4</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Conclusion of Customer Agreement</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>4.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The presentation of the APIP Services on the APIP Webshop does not
                            constitute a legally binding offer by LHT, but merely an invitation for
                            the Customer to place orders (<i>“invitatio ad offerendum”</i>).
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>4.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            For concluding a Customer Agreement, the Customer shall chose on the
                            APIP Webshop the APIP Services it intends to agree with LHT. By clicking
                            “Submit Order” in the last step of the order process, the Customer
                            submits a binding offer for booking the APIP Services as displayed in
                            the order overview.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>4.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            After submitting an offer, LHT shall send Customer an e-mail confirming
                            the receipt of the offer, which does not constitute an order
                            confirmation. Only after LHT has verified availability to perform the
                            requested services, LHT shall send an order confirmation by e-mail to
                            the Customer, which is the final confirmation of the conclusion of the
                            Customer Agreement.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>5</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Terms of Customer Agreement</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>5.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If at the time of the conclusion of the Customer Agreement a framework
                            agreement is in force and effect between the Parties and if an APIP
                            attachment to such framework agreement has been concluded, which applies
                            for the relevant aircraft family of the Aircraft that shall be subject
                            to the APIP Services, the terms of such framework agreement and its APIP
                            attachment shall apply to the Customer Agreement and the following terms
                            of these Terms and Conditions shall only apply supplementary.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>5.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If at the time of the conclusion of the Customer Agreement a framework
                            agreement is in force and effect between the Parties and if no APIP
                            attachment to such framework agreement has been agreed between the
                            Parties, the Parties shall endeavour to conclude such APIP attachment
                            prior to LHT’s rendering of the agreed APIP Services. If the Parties
                            conclude such attachment to the framework agreement prior to the
                            execution of the agreed APIP Services, the provision of said APIP
                            Services shall be governed by the framework agreement and the APIP
                            attachment and the following terms of these Terms and Conditions shall
                            only apply supplementary. If the Parties fail to agree on an APIP
                            Attachment prior to the execution of the APIP Services, then the
                            provisions of these terms and conditions shall fully apply to the APIP
                            Services.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>5.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If no framework agreement is in force and effect between the Parties,
                            then the provisions of these terms and conditions shall fully apply to
                            the Customer Agreement.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Obligations of Customer</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall provide LHT at its own costs with all information,
                            data and documents necessary for the performance of the APIP Services
                            such as, but not limited to, the necessary Aircraft specification. LHT
                            shall not be responsible or held liable for any incorrect results of the
                            APIP Services or any delays insofar as such results or delays are due to
                            incorrect information, data and/or documents provided by the Customer.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall ensure that LHT is granted access to the Aircraft for
                            the performance of the APIP Services and to any premises of the
                            Manufacturer where the Aircraft or its parts is located.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall arrange offices for a reasonable number of LHT
                            personnel at the relevant facilities where the Aircraft is located with
                            sufficient space, equipment, telecommunication and high speed internet
                            connection lines at no cost to LHT, provided such use remains within the
                            professional duties of LHT’s personnel hereunder.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall make available appropriate personnel to liaise with
                            LHT in order to clarify any technical or commercial matters relevant for
                            the performance of the APIP Services without undue delay.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>6.5</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall authorize LHT and its subcontractors for the
                            performance of APIP Services at the Manufacturer’s premises. LHT shall
                            have access to any data that is required for the performance of the APIP
                            Services.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>7</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5>
                            <strong
                                >Responsibilities towards the Airworthiness Authorities and Third
                                Parties</strong
                            >
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>7.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall remain legally responsible towards any Authorities,
                            government agencies and the NAA in relation to any APIP Services
                            performed by LHT for and on behalf of the Customer. LHT shall grant the
                            Customer access to any and all necessary information concerning LHT’s
                            compliance with EASA-requirements or NAA-requirements, as applicable, in
                            order for the Customer to exercise its responsibilities towards the
                            Authorities.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>7.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT shall not have the responsibility towards the Authorities, any OEM,
                            manufacturer or supplier in any way whatsoever including following up
                            any such OEM’s, manufacturer’s or supplier’s service bulletins or
                            airworthiness directives issued by the respective Authority unless
                            otherwise stipulated herein.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>8</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Scope of Services</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>8.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The APIP Services shall consist of the services for the relevant
                            Aircraft as specified in the Customer’s offer pursuant to clause 4.2.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>8.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            When rendering the APIP Services, LHT issues non-binding recommendations
                            only. For the avoidance of doubt, the final responsibility if and to
                            what extend such non-binding recommendations are followed and used by
                            Customer towards the Manufacturer and any involved Authority shall at
                            all times remain with the Customer.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-1">
                        <strong>8.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            For the avoidance of doubt, the Customer shall remain the accepting
                            party of the Aircraft, shall be solely responsible for the acceptance of
                            the Aircraft and shall be solely responsible towards any Authority. The
                            responsibility for the technical and commercial acceptance of the
                            Aircraft, including the appropriate signatures as well as any authority
                            relevant documentation, shall remain entirely with the Customer at all
                            times.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>8.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT only performs visual inspections during the APIP Services. Under no
                            circumstances shall LHT be liable for any defect or deficiency, which
                            could not have been reasonably detected during such a visual inspection
                            of the Aircraft or its section.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>9</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Amendments to the APIP Servicess</strong></h5>
                    </div>
                </div>
                <p class="text_padding">
                    If during the term of the Customer Agreement, LHT becomes aware of the fact that
                    it is necessary or advisable to modify any tasks related to the APIP Services,
                    LHT shall submit to the Customer a proposal in textform containing the suggested
                    amendments. The Parties shall use reasonable endeavours to come an agreement
                    regarding such modifications, which shall only be valid if agreed at least in
                    textform.
                </p>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>10</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Performance Times; Deadlines</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>10.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Dates and deadlines indicated by LHT for the completion of the APIP
                            Services are provisional and non-binding and shall serve as general
                            information only, unless they have been explicitly agreed between the
                            Parties as binding or confirmed by LHT as binding.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>10.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If LHT becomes aware that it is likely to miss the indicated date or
                            deadline, LHT shall notify the Customer thereof without unreasonable
                            delay.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>10.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            In any case, it shall not be LHT’s fault and LHT shall not be held
                            liable if performance dates or other deadlines are not met for reasons
                            beyond LHT’s reasonable control such as, but not limited to:
                        </p>
                    </div>
                </div>
                <br />
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(i)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>Force Majeure;</p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(ii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Unforeseen major defects on airframe, systems, engines or components
                            which could not have been expected;
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(iii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Aircraft, material, documentation, insurance certificates or securities
                            to be provided by the Customer not being available on time, not being
                            suitable for the APIP Services or the Customer not accepting suitable
                            material offered by LHT;
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(iv)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer withholding or delaying any of its contractual obligations
                            under this Customer Agreement;
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(v)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Additional tasks which are not part of the initial APIP Services being
                            carried out by LHT upon the Customer's request.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>11</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Subcontracting</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>11.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT may subcontract the APIP Services or parts thereof to its Affiliates
                            or any other LHT-approved subcontractor.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>11.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT may provide the subcontractor with any information about the
                            Customer that is required to perform the subcontracted APIP Services.
                            Upon request by the Customer LHT shall provide the Customer with any
                            reasonable information about the respective subcontractor, subject to
                            any applicable confidentiality provisions.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>12</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Reporting</strong></h5>
                    </div>
                </div>
                <p class="text_padding">
                    Where appropriate due to the nature of the APIP Services, LHT shall provide
                    free-of-charge reports in electronic form to the Customer. Such reports shall
                    include the following information:
                </p>
                <br />
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(i)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            detailed list of completed inspections and the status of any
                            discrepancies detected;
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(ii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Digital pictures of inspected items, as required and if authorised by
                            Manufacturer;
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>13</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Warranty</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>13.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If the APIP Services are subject to the provisions of the contracts for
                            works pursuant to sections 631 et seqq. BGB (“Werkvertragsrecht”), the
                            following provisions of this clause 13 shall apply.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>13.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The warranty period for the APIP Services is twelve months starting from
                            acceptance.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>13.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If a defect is covered by this warranty, LHT shall first have the
                            opportunity to correct any defect at its own cost and expense at LHT’s
                            choice by means of rectification of the defect or renewed service
                            provision (“Subsequent Performance”). If such Subsequent Performance has
                            failed or if a reasonable time limit for the Subsequent Performance to
                            be set by the Customer has expired without a result or is superfluous,
                            the Customer may request (i) a reduction in price or (ii) may withdraw
                            from the Customer Agreement and (iii) claim damages. The Customer,
                            however, is only entitled to claim damages in accordance with clause 14
                            of these Terms and Conditions.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>13.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The warranty period of clause 13.2 shall also apply to contractual and
                            non-contractual claims for damages, which are based on a defect in the
                            APIP Services. However, if LHT is liable for damages in accordance with
                            clause 14.2, the statutory limitation period shall apply.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Liability</strong></h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Claims for damages and compensation against LHT regardless on which
                            legal grounds only exist pursuant to the following terms of this cl. 14
                            and are otherwise excluded.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT is liable under the statutory provisions for culpably caused injury
                            to life, limb and health including simple negligence as well as
                            otherwise for wilful misconduct and gross negligence. LHT is further
                            liable pursuant to statutory provisions if LHT concealed any defect in
                            its APIP Services in bad faith or issued a guaranty. The same applies to
                            the Customer’s claims under the German Product Liability Act
                            [<i>“Produkthaftungsgesetz”</i>].
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If there is no event which falls within clause 14.2 above, LHT’s
                            liability for loss or damage is excluded in the event of simple
                            negligence, unless LHT has culpably breached a material contractual
                            obligation. Material contractual obligations are those the performance
                            of which make the proper fulfilments of the contract at all possible and
                            on the compliance of which the Customer regularly relies upon and is
                            entitled to so rely.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If LHT is liable in accordance with clause 14.3 due to a simple
                            negligent breach of material contractual obligations, LHT's liability
                            shall be further limited as follows: LHT shall not be liable for
                            non-foreseeable damages, which are not typical for services of the kind
                            constituting the APIP Service under the relevant Customer Agreement.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.5</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Non-foreseeable damages within the meaning of clause 14.3 above shall in
                            any case be damages exceeding 100% of the contract price under the
                            relevant Customer Agreement.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.6</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Insofar LHT’s liability for damages is excluded or limited, this shall
                            also apply with regard to the personal liability for damages of LHT’s
                            employees, directors, officers, agents and subcontractors.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>14.7</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Notwithstanding anything to the contrary herein, if the Customer and the
                            Manufacturer have agreed on a liability and indemnification provision
                            that reduces the liability and indemnification obligations of
                            Manufacturer to an extent that is more favourable for the Manufacturer
                            than the liability and indemnification provisions applicable between the
                            Customer and LHT herein, such further, additional reduction of liability
                            and indemnification obligations of the Manufacturer shall also apply for
                            the benefit of LHT under this Customer Agreement.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>15</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Insurance / Reinsurance</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>15.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            For any Aircraft, with effect from delivery of the Aircraft from the
                            Manufacturer to the Customer and for a minimum period of two (2) years
                            thereafter (save as for products legal liability provided that the
                            Customer remains the operator of the Aircraft during this period), the
                            Customer shall effect, maintain and provide LHT with a certificate of
                            insurance (or, if requested by LHT, a certificate of reinsurance)
                            evidencing the following coverages in line with industry standard:
                        </p>
                    </div>
                </div>
                <br />
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(i)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>Hull all risks insurance, including war risks;</p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(ii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>All risks property insurance, including war risks;</p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(iii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Aviation general legal liability insurance, including but not limited to
                            third party, products, war and passenger risks in amount of not less
                            than seven hundred and fifty million United States Dollars (750,000,000
                            USD).
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>15.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The insurances listed in clause 15.1 (i) and (ii) shall contain a waiver
                            of subrogation in favour of LHT, its directors, officers, employees,
                            agents and subcontractors except in case they are liable according to
                            this agreement.
                        </p>
                        <p>
                            LHT, its directors, officers, employees, agents and subcontractors shall
                            be named as additional insureds with regard to the insurance listed in
                            clause 15.1 (iii) to the extent of the insurance and indemnity
                            obligations in this agreement and such insurance shall provide for a
                            market standard primary, severability of interest, breach of warranty
                            and notice of cancellation clause.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>15.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            In case any of the insurances are not provided or are revoked or
                            cancelled at any time for any reason, LHT may terminate the Customer
                            Agreement by giving the Customer three (3) days notice in writing.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>16</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Prices</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>16.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>The Customer shall pay the price for the APIP Services as agreed.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>16.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            All prices under the Customer Agreement are exclusive of any VAT or
                            similar tax on value or turnover payable in respect thereof, which Tax,
                            if any, shall be payable by the Customer in addition thereto and at the
                            same time. Subject to this clause 16.2, all payments made by the
                            Customer under the Customer Agreement shall be made gross without any
                            Tax deductions or withholdings of a similar nature.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>16.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            If the Customer is required by law to make any deduction or withholding
                            from any payment hereunder, it shall do so and the sum due from the
                            Customer in respect of such payment shall be increased to the extent
                            necessary to ensure that, after the making of such deduction or
                            withholding, LHT receives and retains (free of any liability in respect
                            of any such deduction or withholding) a net sum equal to the sum it
                            would have received and retained had no deduction or withholding been
                            required to be made.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>16.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT shall be responsible for Excluded Taxes. The Customer shall assume
                            full responsibility for and indemnify and hold harmless LHT on LHT's
                            demand from and against any and all Taxes which may arise from this
                            Agreement and the transaction, acts events and circumstances envisaged
                            thereby and in respect to the receipts of LHT hereunder.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>17</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Payment Terms</strong></h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-1">
                        <strong>17.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT may issue its invoices at the beginning of the agreed APIP Services.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>17.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            All payments are due and payable within 30 days after receipt of the
                            relevant invoice(s) without any deduction.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>17.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT always reserves the right to require prepayment for any APIP
                            Services. In case of such prepayment requirement, Customer shall be
                            notified by LHT.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>17.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer shall not be entitled to set off any counter-claims against
                            LHT’s claims or exercise a right of retention, unless the Customer’s
                            counter-claim or right of retention is determined by a final decision of
                            a court or are undisputed.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>17.5</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT shall always be entitled to assign its payments claims against the
                            Customer to third parties.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>18</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5>
                            <strong
                                >Lien, Authority to Sale; Right of Retention; Set-Off Rights</strong
                            >
                        </h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-1">
                        <strong>18.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT has by virtue of the APIP Services performed a contractual lien with
                            respect to the object of APIP Services in its custody as well as with
                            respect to other items of the Customer in LHT's custody to secure any
                            claims of LHT against the Customer out of or in connection with the
                            Customer Agreement as well as to secure any claims against the Customer
                            of LHT’s Affiliates. Such contractual lien shall also exist for claims
                            resulting from services previously performed or material previously
                            supplied. The contractual lien shall entitle LHT and/or any of its
                            Affiliates to publicly offer the object of APIP Services and/or other
                            items in LHT’s custody for sale no earlier than one (1) month after
                            advising the Customer of its intent to do so. To effect such sale LHT
                            shall not be required to obtain an enforceable title or to comply with
                            the regulations governing forced sale.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>18.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Further, LHT has by virtue of the APIP Services performed a right of
                            retention with respect to the object of Services in its custody as well
                            as with respect to any other items of the Customer in LHT's custody to
                            secure any claims of LHT against the Customer out of or in connection
                            with the Customer Agreement as well as to secure any claims of
                            Affiliates against the Customer. Such right as well as a set-off right
                            may also be asserted for services previously performed or material
                            previously supplied.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>18.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            LHT shall also have the right to cease any ongoing APIP Services without
                            notice until all payments due under the Customer Agreement or any other
                            contractual relationship between the Customer and LHT or the Customer
                            and any Affiliate have been made.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>19</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Assignment</strong></h5>
                    </div>
                </div>

                <p class="text_padding">
                    In addition to any other statutory or contractual right of assignment, LHT may
                    assign its rights and obligations under the Customer Agreement in total or in
                    part to any of its Affiliates. The Customer shall be informed about such
                    assignment duly in advance. The assignee shall perform the APIP Services under
                    the Customer Agreement in the quality and standards and in accordance with the
                    terms and conditions laid down in these Terms and Conditions. Any other
                    assignment of rights or obligations arising from the Customer Agreement shall
                    require the prior approval in written form of the other Party.
                </p>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>20</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Force Majeure</strong></h5>
                    </div>
                </div>
                <p class="text_padding">
                    All events or circumstances, the prevention of which is beyond the control of
                    LHT, such as, but not limited to, acts of the public enemy, terrorism, war,
                    insurrections or riots, blockades, fires, floods, explosions, earthquakes,
                    storms, serious accidents, epidemics, pandemics, endemics or quarantine, any act
                    or omission of government or governmental authority (such as, but not limited
                    to, delays with any customs clearance despite LHT having properly applied for it
                    in due time or as a result of clarifications with customs authorities), strikes
                    or labor troubles causing cessation, slowdown or interruption of work, general
                    hindrance in transportation, general supply shortages and interruptions, shall
                    release LHT from its obligation of providing APIP Services for the duration and
                    to the extent of such events or circumstances.
                </p>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>21</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Export Clause</strong></h5>
                    </div>
                </div>
                <p class="text_padding">
                    The Customer shall comply with all applicable domestic and foreign export
                    compliance requirements, including applicable US export laws and regulations and
                    those of other relevant foreign jurisdictions. Upon LHT’s request, the Customer
                    shall promptly provide LHT with appropriate documents or certifications as
                    required by such applicable export laws and regulations, or as necessary to
                    ensure continuing compliance with such applicable export laws and regulations.
                    LHT reserves the right to not execute shipment until the Customer has provided
                    necessary documents or certifications to enable LHT to use existing export
                    licenses such as acknowledgement of LHT’s license conditions.
                </p>
                <br />

                <div class="row">
                    <div class="col-sm-1">
                        <strong>22</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Confidentiality</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Parties shall treat any Confidential Information as strictly
                            confidential. The Parties shall not disclose Confidential Information in
                            any way or form to any other person except as permitted by this clause
                            22.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Neither Party shall disclose any Confidential Information to any
                            employee, except where such disclosure is necessary in order to fulfil
                            the obligations or execute any rights granted under the Customer
                            Agreement and in case the employee is committed to comply with the
                            relevant Party's confidentiality obligations. Either Party may disclose
                            Confidential Information to its tax consultants, certified accountants
                            and attorneys, such persons to be instructed to adhere to the terms of
                            this clause 22 and as required by applicable law. LHT may disclose
                            certain information including Confidential Information to its Assistants
                            and/or the relevant OEM.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.3</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Any Beneficiary shall not be bound by the confidentiality obligations
                            under this clause 22 if the Beneficiary can prove that the Confidential
                            Information:
                        </p>
                    </div>
                </div>
                <br />
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(i)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            was already known to the Beneficiary at the date such Beneficiary
                            received said information; or
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(ii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            was already known to the public prior to the date the Beneficiary
                            received said information; or
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(iii)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            has become known to the public after the date the Beneficiary received
                            said information, except if the Beneficiary is the originator of the
                            publication of said information; or
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(iv)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            has been communicated to the Beneficiary at any date by a third party
                            with the right to communicate it; or
                        </p>
                    </div>
                </div>
                <div class="row text_padding">
                    <div class="col-sm-1">
                        <strong>(v)</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            shall be disclosed because of mandatory law or a binding and final order
                            of a competent court or authority, in which case the Beneficiary shall
                            immediately inform the other Party about such mandatory disclosure and
                            limit the disclosure to the extent legally permissible.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong> </strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The aforementioned exceptions to the confidentiality obligations under
                            this clause 22 shall apply to a combination of single pieces of
                            Confidential Information only if such combination is subject to one of
                            the aforementioned exceptions but not if only each individual piece of
                            Confidential Information is subject of said exceptions.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.4</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            All obligations of confidentiality imposed under this clause 22 shall
                            survive the term of the Customer Agreement for a period of five (5)
                            years. The Parties shall return any Confidential Information and/or the
                            copies thereof to the disclosing Party upon the termination of the
                            Customer Agreement.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.5</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Each Party shall keep the originals of the documents which it is obliged
                            to keep under applicable law and shall deliver a copy of those to the
                            other Party upon request.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.6</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            Each Party shall notify the other Party in case of becoming aware of a
                            breach of this clause 22 and shall use all reasonable endeavours to
                            ensure that this breach is cured immediately.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>22.7</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Parties shall use, copy or reproduce Confidential Information into
                            tangible, visible, electronic or recorded form only when it is essential
                            to provide the APIP Services and shall treat copies or reproductions
                            confidential in accordance with this clause 22.
                        </p>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>23</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Intellectual Property Rights</strong></h5>
                    </div>
                </div>
                <p class="text_padding">
                    LHT shall at all-time retain all right, title and interest in and to the
                    Intellectual Property Rights contained in the APIP Services, material, products,
                    documents, data and processes. The disclosure of any documents, data and other
                    information in connection with the Customer Agreement including, without
                    limitation, when such disclosure is made in fulfilment of a contractual
                    obligation hereunder, shall not be construed as a grant or transfer of such
                    rights.
                </p>
                <br />
                <div class="row">
                    <div class="col-sm-1">
                        <strong>24</strong>
                    </div>
                    <div class="col-sm-11">
                        <h5><strong>Law and Jurisdiction</strong></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>24.1</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The Customer Agreement and these Terms and Conditions and any legal
                            relationship with the Customer that may arise therefrom shall be
                            exclusively subject to and construed exclusively in accordance with the
                            laws of the Federal Republic of Germany excluding their conflict of laws
                            rules. The United Nations Convention on the International Sale of Goods
                            (CISG) shall not apply.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-1">
                        <strong>24.2</strong>
                    </div>
                    <div class="col-sm-11">
                        <p>
                            The courts of Hamburg, Germany, shall have jurisdiction. In case of any
                            claims asserted against LHT this jurisdiction shall be exclusive.
                        </p>
                    </div>
                </div>
                <span
                    style="
                        font-family: var(--font-family-text);
                        margin-top: 100px;
                        margin-left: 4rem;
                    "
                >
                    Last change: 11th of July, 2023
                </span>
                <br />
                <span style="font-family: var(--font-family-text); margin-left: 4rem">
                    Find our Privacy Policy
                    <a href="/privacy-policy-form" target="_blank">here</a>.
                </span>
            </div>
        </app-form-wizard>
    </div>
</body>
