import { createAction, props } from "@ngrx/store";
import { ProductSelection } from "../../model/product-selection.interface";

export const patch = createAction(
    "[Product Selection] Patch Value",
    props<{ payload: Partial<ProductSelection> }>()
);

export const changeValidationStatus = createAction(
    "[Product Selection] Change Validation Status",
    props<{ isValid: boolean }>()
);

export const ProductSelectionActions = {
    patch,
    changeValidationStatus,
};
